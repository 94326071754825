.paginationContainer {
  display: flex;
  list-style-type: none;
  align-items: center;
  position: fixed;
  right: 50px;
  li:hover {
    border-color: rgba(0, 122, 255, 1);
    color: rgba(0, 122, 255, 1);
    background-color: rgba(36, 176, 255, 0.1);
    border-width: 1px;
    &.selected {
      border-color: rgba(0, 122, 255, 1);
      color: white;
      background-image: none;
      background-color: rgba(0, 122, 255, 1);
      border-width: 1px;
    }
  }
  li {
    margin: 10px;
    border-width: 1px;
    border-color: rgba(51, 51, 51, 0.6);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    color: rgba(51, 51, 51, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    &.previous {
      color: rgba(51, 51, 51, 0.6);
      font-size: 14px;
      border: none;
      &:hover {
        background: none;
      }
    }
    &.previous.disabled {
      color: rgba(51, 51, 51, 0.3);
    }
    &.next {
      color: rgba(51, 51, 51, 0.6);
      font-size: 14px;
      border: none;
      &:hover {
        background: none;
      }
    }
    &.next.disabled {
      color: rgba(51, 51, 51, 0.3);
    }

    &.selected {
      border-color: rgba(0, 122, 255, 1);
      color: white;
      background-image: none;
      background-color: rgba(0, 122, 255, 1);
      border-width: 1px;

      &.hover {
        background-image: none;
        border-width: 1px;
      }
    }
    a {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.break {
      a {
        display: flex;
        align-self: center;
        padding-bottom: 5px;
      }
    }
  }

  &.dark {
    li {
      border-color: rgba(255, 255, 255, 0.6);
      color: rgba(255, 255, 255, 0.6);
      &.previous {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        border: none;
      }
      &.next {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        border: none;
      }
      &.previous.disabled {
        color: rgba(255, 255, 255, 0.3);
      }
      &.next.disabled {
        color: rgba(255, 255, 255, 0.3);
      }
      &.selected {
        border-color: rgba(0, 122, 255, 1);
        color: rgba(0, 122, 255, 1);
      }
      &.break {
        a {
          height: 90%;
          display: flex;
          align-self: center;
        }
      }
    }
  }
}
